/* App.css */
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  /* para transições suaves */
}

.container {
  height: 100%;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  /* define o snap vertical obrigatório */
}

.snap-section {
  height: 100vh;
  /* cada seção ocupa 100% da altura da viewport */
  scroll-snap-align: start;
  /* alinha cada seção no início ao dar scroll */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  overflow-y: hidden;
}

.snap-section1 {
  height: 30vh;
  /* cada seção ocupa 100% da altura da viewport */
  scroll-snap-align: start;
  /* alinha cada seção no início ao dar scroll */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  overflow-y: hidden;
}

/* Opcional: estilos para os títulos e parágrafos */
h1 {
  margin-bottom: 10px;
  color: #333;
}

p {
  max-width: 600px;
  text-align: center;
  line-height: 1.5;
  color: #555;
}